<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { subjectCode: this.propsObj.row.budgetSubjectsCode };
    this.getConfigList('sub_subjects_list');
  },
  methods: {},
};
</script>
