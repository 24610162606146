<template>
    <div class="box">
        <div class="title">主科目信息</div>
        <div class="text">{{subjectObj.budgetSubjectsName}}({{subjectObj.budgetSubjectsCode}})</div>
    </div>
</template>
<script>
export default {
  name: 'MianSubjectsForm',
  props: {
    subjectObj: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    subjectObj(newVal, oldVal) {
      this.subjectObj = newVal;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.box{
    display: flex;
}
.title{
    font-size: 16px;
    font-weight: bold;
    margin-right: 40px;
}
.text{
    font-size: 16px;
}
</style>
