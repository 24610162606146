var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "title" }, [_vm._v("主科目信息")]),
    _c("div", { staticClass: "text" }, [
      _vm._v(
        _vm._s(_vm.subjectObj.budgetSubjectsName) +
          "(" +
          _vm._s(_vm.subjectObj.budgetSubjectsCode) +
          ")"
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }