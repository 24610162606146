import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import BudgetAccountList from './budget_list.vue';
import CompanyEntityList from './company_list.vue';

formCreate.component('BudgetAccountList', BudgetAccountList);
formCreate.component('CompanyEntityList', CompanyEntityList);
export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'budget_subjects_form',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'coBudgetSubjectName') { // 选择预算科目
        v.props = {
          ...v.props,
          params: {
            functionCode: 'budget_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'coCompanyName') { // 选择公司主体
        v.props = {
          ...v.props,
          params: {
            functionCode: 'company_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      return v;
    },
    // 表单渲染完成后的回调
    formComplete() {
      this.hiddenFields(true, ['fiscalYearArr', 'coBudgetSubjectName', 'coCompanyName']);
      const carryoverIs = this.getRule('carryoverIs');
      const coBudgetSubjectName = this.getRule('coBudgetSubjectName');
      const coCompanyName = this.getRule('coCompanyName');
      // 预算科目
      carryoverIs.on.change = (e) => {
        if (e === 'N' || !e) {
          this.hiddenFields(true, ['fiscalYearArr', 'coBudgetSubjectName', 'coCompanyName']);
        } else {
          this.hiddenFields(false, ['fiscalYearArr', 'coBudgetSubjectName', 'coCompanyName']);
        }
      };
      // 预算科目
      coBudgetSubjectName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            coBudgetSubjectName: e[0].budgetSubjectsName,
            coBudgetSubjectCode: e[0].budgetSubjectsCode,
          });
        } else {
          this.setValue({
            coBudgetSubjectName: null,
            coBudgetSubjectCode: null,
          });
        }
      };
      // 公司主体
      coCompanyName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            coCompanyName: e[0].companyName,
            coCompanyCode: e[0].companyCode,
          });
        } else {
          this.setValue({
            coCompanyName: null,
            coCompanyCode: null,
          });
        }
      };
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.get('/tpm/tpmBudgetSubjectsController/query', {
          id: this.formConfig.row.id,
        }).then((res) => {
          if (res.success) {
            if (res.result.carryoverIs === 'N' || !res.result.carryoverIs) {
              this.hiddenFields(true, ['fiscalYearArr', 'coBudgetSubjectName', 'coCompanyName']);
            } else {
              this.hiddenFields(false, ['fiscalYearArr', 'coBudgetSubjectName', 'coCompanyName']);
            }
            const { result } = res;
            if (result.fiscalYear) {
              result.fiscalYearArr = result.fiscalYear.split(',');
            }
            this.setValue(result);
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        if (formData.fiscalYearArr && formData.fiscalYearArr.length > 0) {
          formData.fiscalYear = formData.fiscalYearArr.join(',');
        } else {
          formData.fiscalYear = null;
        }
        delete formData.fiscalYearArr;
        let url = '/tpm/tpmBudgetSubjectsController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmBudgetSubjectsController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
