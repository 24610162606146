import BasePage from '../../../../../components/table_page';
import Form from './form';
import CheckDetail from './form/check_detail.vue';
import BindSubSubjects from './form/bind_sub_subjects.vue';

export default {
  name: 'budget_subjects',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    CheckDetail,
    BindSubSubjects,
  },
  created() {
    this.getConfigList('budget_subjects_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.formConfig = {
          code: val.code,
          row,
        };
        this.modalConfig.width = '500px';
        this.modalConfig.title = val.name || '查看';
        this.openModal();
      }
      if (val.code === 'check_detail') {
        this.formName = 'CheckDetail';
        this.propsObjInData.row = row;
        this.modalConfig.title = '主/子科目关系明细';
        this.openFull();
      }
      if (val.code === 'bind_sub_subjects') {
        this.formName = 'BindSubSubjects';
        this.formConfig = {
          row,
        };
        this.modalConfig.title = '绑定主/子科目';
        this.openFull();
      }
    },
  },
};
