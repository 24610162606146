<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import SubSubjects from './sub_subjects.vue';
import MainSubject from './mian_subjects.vue';

formCreate.component('SubSubjects', SubSubjects);
formCreate.component('MainSubject', MainSubject);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      addData: [{
        subjectCode: this.formConfig.row.budgetSubjectsCode,
        subjectName: this.formConfig.row.budgetSubjectsName,
        subSubjectCode: '',
        subSubjectName: '',
        subjectsSubOrgReqVos: [],
        startTime: '',
        endTime: '',
        executeTime: '',
      }],
      formFunctionCode: 'bind_sub_subjects',
    };
  },

  methods: {
    submit() {
      const detailVoList = this.fApi.method('subSubjects', 'getFormData')();
      let isSubSubjectCode = null;
      let isSubjectsSubOrgReqVos = null;
      let isTime = null;
      isSubSubjectCode = (detailVoList || []).find((v) => !v.subSubjectCode);
      isSubjectsSubOrgReqVos = (detailVoList || []).find((v) => v.subjectsSubOrgReqVos.length <= 0);
      isTime = (detailVoList || []).find((v) => !v.startTime || !v.endTime);
      if (isSubSubjectCode) {
        this.$message.error('子预算科目没有选择，请确认！');
        return;
      }
      if (isSubjectsSubOrgReqVos) {
        this.$message.error('子预算组织没有选择，请确认！');
        return;
      }
      if (isTime) {
        this.$message.error('子预算有效期没有选择，请确认！');
      }
      if (detailVoList && detailVoList.length > 0) {
        const url = '/tpm/tpmbudgetsubjectssub/save';
        request.post(url, detailVoList).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
    setRule(item) {
      const rowData = item;
      if (rowData.field === 'mainSubject') {
        rowData.props.subjectObj = {};
      }
      if (rowData.field === 'subSubjects') {
        rowData.props.subjectData = [];
        rowData.props.budgetSubjectsCode = null;
        rowData.props.budgetSubjectsName = null;
      }
      return rowData;
    },
    formComplete() {
      const mainSubject = this.getRule('mainSubject');
      mainSubject.props = {
        ...mainSubject.props,
        subjectObj: this.formConfig.row,
      };
      const subSubjects = this.getRule('subSubjects');
      subSubjects.props = {
        ...subSubjects.props,
        subjectData: this.addData,
        budgetSubjectsCode: this.formConfig.row.budgetSubjectsCode,
        budgetSubjectsName: this.formConfig.row.budgetSubjectsName,
      };
    },
  },
  created() {},
};

</script>
